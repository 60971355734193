import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap"
import { ArrowLeft, CameraFill, Envelope, Flag } from 'react-bootstrap-icons';
import { countryCode2name, countryCode2flag } from '../constants'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import ImageUploadForm from "./ImageUploadForm";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Form from 'react-bootstrap/Form';

function HillInfo() {
    const { state } = useLocation();
    // console.log(state)
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(1);
    const [dataHillInfo, setDataHillInfo] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [toastMsg, setToastMsg] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [comment, setComment] = useState('');
    const [capturedByUser, setCapturedByUser] = useState(false);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/hillinfo/?hill_id=${state.hill_id}`)
            .then(response => {
                // Handle success
                // console.log(response.data)
                setDataHillInfo(response.data);
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }, []);


    useEffect(() => {
        const isCaptured = dataHillInfo?.hill_visits.some((visit) => visit.username === localStorage.getItem('username'));
        setCapturedByUser(isCaptured)
        // console.log('capturedByUser', capturedByUser)
    }, [dataHillInfo]);


    const HillInfoCard = () => {
        return <div className="card card-body m-2 text-center">
            <div className="fs-2">{dataHillInfo.hill_info.name}</div>
            <div >{dataHillInfo.hill_info.hight} m</div>

            <div className='row'>
                <div className='col-6'>
                    <div className="row">
                        <div className="col"><img alt='' src={require(`../../assets/flags/${countryCode2flag[dataHillInfo.hill_info.region.substring(0, 3)]}.png`)} /></div>
                    </div>
                    <div className="row">
                        <div className="col">{countryCode2name[dataHillInfo.hill_info.region.substring(0, 3)]}</div>
                    </div>
                </div>
                <div className='col-6 d-flex align-items-center justify-content-center fs-3'>
                    <Flag className='me-2' size={20} />{dataHillInfo.hill_visits.length}
                </div>
            </div>

            {capturedByUser &&
                <div className='row'>
                    <div className='col-6'>
                        <button type="button" className="btn btn-primary" onClick={() => setShowCommentModal(true)}>
                            Add Comment
                        </button>
                    </div>
                    <div className='col-6'>
                        <ImageUploadForm hill_id={state.hill_id} setShowToast={setShowToast} setToastMsg={setToastMsg} />
                    </div>
                </div>}
        </div>
    }

    const CapturedBy = () => {
        return (
            <ListGroup>
                {dataHillInfo.hill_visits.map((capture, index) => (
                    <ListGroup.Item key={index}
                        onClick={() => {
                            if (capture.is_img === 'Y') {
                                console.log('Image clicked!', capture.img_url);
                                handleImageLineClick(capture.img_url)
                            }
                        }}
                    >
                        <div className="row d-flex align-items-center">
                            <div className="col-3">
                                <img alt='' src={require(`../../assets/flags/${countryCode2flag[capture.user_region]}.png`)} />
                            </div>
                            <div className="col-7">
                                <div className="row">
                                    <div className="col text-capitalize">{capture.username}</div>
                                </div>
                                <div className="row">
                                    <div className="col">{capture.cap_date.slice(0, 16).replace(/T/g, ' ')} UTC</div>
                                </div>
                            </div>
                            <div className="col-1">
                                {capture.is_img === 'Y' && <CameraFill />}
                            </div>
                            <div className="col-1">
                                {capture.comment && <Envelope />}
                            </div>

                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        )
    }

    const Comments = () => {
        return (
            <ListGroup>
                {dataHillInfo.hill_visits.map((capture, index) => (

                    capture.comment && <ListGroup.Item key={index} >
                        <div className="row d-flex align-items-center">
                            <div className="col-3">
                                <img alt='' src={require(`../../assets/flags/${countryCode2flag[capture.user_region]}.png`)} />
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col text-capitalize">{capture.username}</div>
                                </div>
                                <div className="row">
                                    <div className="col">{capture.comment_date.slice(0, 16).replace(/T/g, ' ')} UTC</div>
                                </div>
                            </div>

                        </div>
                        <hr className="border-2 border-top my-1 bg-secondary" />
                        <div className="row">
                            {capture.comment}
                        </div>
                    </ListGroup.Item>

                ))}
            </ListGroup>
        )
    }

    const HillCapturesCard = () => {
        return <>
            {/* <div className="card card-body m-2"> */}
            <Tabs activeKey={activeTab} onSelect={handleTabSelect} id="noanim-tab-example" className="nav-pills nav-fill">
                <Tab eventKey={1} title="Captured By">
                    <CapturedBy />
                </Tab>
                <Tab eventKey={2} title="Comments">
                    <Comments />
                </Tab>

            </Tabs>
            {/* </div> */}
        </>
    }

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };


    if (!dataHillInfo) {
        return <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>
    }


    const handleShowDialogWithImage = () => {
        setShowImageModal(!showImageModal);
    };

    
    const handleShowDialogWithComment = () => {
        setShowCommentModal(!showCommentModal);
    };


    const handleImageLineClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowImageModal(true);
    };

    const handleCommentChange = async (e) => {
        e.preventDefault();

        await axios.post(process.env.REACT_APP_BACKEND_URL + `/v2/api/change_comment/`, {
            "hill_id": state.hill_id, 'comment': comment
        })
            .then(async function (response) {
                console.log(response)
                if (response.data.success) {
                    console.log('comment submited')
                    setToastMsg('comment successfully submited. Refresh page to see it.')
                } else {
                    console.log('comment not submited')
                }
            })
            .catch(function (error) {
                console.log(error);
                setToastMsg('Unknown error')

            });
        setShowToast(true)
        setShowCommentModal(false)
    };


    return (
        <>
            <div className='navbarmargin'></div>

            <div>
                <ArrowLeft style={{ position: 'absolute', margin: '5px', color: 'blue' }} size={30} onClick={() => navigate(-1)} />
                <div className="p-2 bg-light text-dark">
                    <div className='text-center'>Hill Info</div>
                </div>
            </div>

            <HillInfoCard />
            <HillCapturesCard />


            {/* // modal with user's hill image */}
            <div>
                <Modal show={showImageModal} onHide={handleShowDialogWithImage}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <img
                            className="img-fluid"
                            src={selectedImage}
                            alt=""
                        />
                    </Modal.Body>
                </Modal>
            </div>

            {/* // modal to write new comment */}
            <div>
                <Modal show={showCommentModal} onHide={handleShowDialogWithComment} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Decription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleCommentChange}>
                            <Form.Group controlId="formBasicInput">
                                <Form.Label>You will overwrite your previous comment.</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Type here"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    as="textarea" rows={3}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-2">
                                Submit
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>


            

            <ToastContainer position="middle-center" className="p-3" style={{ zIndex: 1 }}>
                <Toast
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000} autohide
                    bg='light'
                >
                    <Toast.Header>
                        <strong>Info</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

        </>
    );


}

export default HillInfo;