import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

function RegisterPage() {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({ 'username': null, 'email': null, 'password': null, 'error': false });
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleRepeatPasswordChange = (e) => {
        setRepeatPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        // if (password !== repeatPassword) {
        //     console.log('Passwords do not match');
        //     return;
        // }

        const formData = new FormData();
        formData.append('username', username);
        formData.append('email', email);
        formData.append('password1', password);
        formData.append('password2', repeatPassword);

        try {
            // Replace with your server endpoint
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + `/accounts/register`, formData);
            console.log('response', response.data);
            if (response.data.success) {
                console.log('success!');
                navigate('/login', { state: { msg: 'We have emailed you activation link. You should receive the email shortly!' } })
                // Handle any other logic (e.g., display a success message)
            } else {
                console.log('errors!');
                // setErrorMsg(response.data.errors.email[0])
                const msgs = {
                    "username": response.data.errors.username ? response.data.errors.username[0] : null,
                    "email": response.data.errors.email ? response.data.errors.email[0] : null,
                    "password1": response.data.errors.password1 ? response.data.errors.password1[0] : null,
                    "password2": response.data.errors.password2 ? response.data.errors.password2.join(" ") : null,
                    "error": response.data.errors.username || response.data.errors.email || response.data.errors.password1 || response.data.errors.password2
                };
                setFieldErrors(msgs)
            }
        } catch (error) {
            console.log('catch!');
            console.error('Error sending POST request:', error);
            setErrorMsg('Unknown error. Are you sure you have internet connection?')
        }
        setIsLoading(false)
    };

    return (
        <>
            <div className='navbarmargin'></div>

            <Container>
                <h2 className="text-center mt-4">Registration</h2>

                {errorMsg && <Alert variant='danger'>{errorMsg}</Alert>}


                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicUsername" className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter username"
                            value={username}
                            onChange={handleUsernameChange}
                            isInvalid={fieldErrors.error}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {fieldErrors.username}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={handleEmailChange}
                            isInvalid={fieldErrors.error}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {fieldErrors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            isInvalid={fieldErrors.error}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {fieldErrors.password1}

                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicRepeatPassword" className="mb-3">
                        <Form.Label>Repeat Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Repeat password"
                            value={repeatPassword}
                            onChange={handleRepeatPasswordChange}
                            isInvalid={fieldErrors.error}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {fieldErrors.password2}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100" disabled={isLoading}>
                        {isLoading && <Spinner as="span" size="sm" />}
                        Register
                    </Button>
                </Form>
                <div className="d-flex mt-3">
                    <div>Already registered?</div>
                    <div className="ms-2"><Link to={'/login'}>Login</Link></div>
                </div>
            </Container>


        </>
    );


}

export default RegisterPage;