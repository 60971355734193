import axios from "axios";
import { setupCache } from 'axios-cache-interceptor';

let refresh = false;


const commonErrorInterceptor = async (error, instance) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !refresh) {
        refresh = true;
        originalRequest._retry = true
        console.log('401 returned by original requet')
        console.log('old refresh token', localStorage.getItem('refresh_token'))
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/v2/token/refresh/', {
            refresh: localStorage.getItem('refresh_token')
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }, { withCredentials: true });

        if (response.status === 200) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`; // this doesnt work
            console.log('replacing access and refresh tokens')
            console.log('new refresh token', response.data.refresh)
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            // window.location.href = '/'
            window.location.replace(window.location.href);
            return instance(error.config);
        } else {
            console.log('refresh token is not valid anymore, redirecting.')
            // localStorage.setItem('access_token', null);
            // localStorage.setItem('refresh_token', null);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/login'
        }
    }
    refresh = false;
    return error;
};


// global instance with refresh only
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});
axios.interceptors.response.use(resp => resp, 
    async error => commonErrorInterceptor(error, axios),);


// local axios instance with refresh and cache
const instance = axios.create({
    // timeout: 60 * 1000, // 1 minute
    timeout: 60 * 1000 * 60, // 60 minutes
});

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

instance.interceptors.response.use(
    resp => resp,
    error => commonErrorInterceptor(error, instance),
);
const axiosWcache = setupCache(instance);

export { axiosWcache }