import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import FloatingMapButton from '../FloatingMapButton';
import axios from 'axios';
import { countryCode2name, countryCode2flag } from "../constants";
import Dropdown from 'react-bootstrap/Dropdown';

const RankingsPre = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('life');
  const [selectedRegion, setSelectedRegion] = useState('world');
  const [selectedChallenge, setSelectedChallenge] = useState('count');
  const [dataDropdowns, setDataDropdowns] = useState(null);
  const [availableRegions, setavailableRegions] = useState(null);


  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/curr_ranks_dropdowns/`)
      .then(response => {
        // Handle success
        // console.log('response', response.data); // Log the data
        const sortedData = response.data.dropdowns.sort((a, b) => countryCode2name[a.region].localeCompare(countryCode2name[b.region]));
        setDataDropdowns(sortedData);
      })
      .catch(error => {
        // Handle error
        console.error(error);
      });
  }, []);


  // set region dropdown based on selected period
  useEffect(() => {
    const available_regions = dataDropdowns?.filter(
      (entry) => entry.period_type === selectedPeriod)
    setavailableRegions(available_regions)
    setSelectedRegion('world')
  }, [dataDropdowns, selectedPeriod]);


  const handleDropdownChange = (event, dropdownNumber) => {
    const selectedValue = event.target.value;
    switch (dropdownNumber) {
      case 1:
        setSelectedPeriod(selectedValue);
        break;
      case 2:
        setSelectedRegion(selectedValue);
        break;
      case 3:
        setSelectedChallenge(selectedValue);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Selected options:');
    console.log('Dropdown 1:', selectedPeriod);
    console.log('Dropdown 2:', selectedRegion);
    console.log('Dropdown 3:', selectedChallenge);
    // Handle form submission here (e.g., send data to the server)
  };


  if (!dataDropdowns) {
    return (
      <>
        <div className='navbarmargin'></div>
        <span>Loading...</span>
      </>
    )
  }

  return (
    <>
      <div className='navbarmargin'></div>

      <Form onSubmit={handleSubmit} className='m-2'>
        <Form.Group controlId="dropdown1" className='mb-3'>
          <Form.Label><b>Period</b></Form.Label>
          <Form.Control as="select" onChange={(e) => handleDropdownChange(e, 1)}>
            <option value="life">Lifetime</option>
            <option value="year">Year</option>
            <option value="quarter">Quarter</option>
          </Form.Control>
        </Form.Group>


        <Form.Group controlId="dropdown2" className='mb-3'>
          <Form.Label><b>Region</b></Form.Label>
          <Dropdown
            onSelect={eventKey => {
              // const { region } = availableRegions.find(({ region }) => eventKey === region);
              setSelectedRegion(eventKey);
            }}
          >
            <div className='form-control p-0'>
              <Dropdown.Toggle variant="light" id="dropdown-flags" className="text-start bg-white" style={{ width: '100%' }}>
                <><img height={25} alt='' src={require(`../../assets/flags/${countryCode2flag[selectedRegion]}.png`)} /> {countryCode2name[selectedRegion]}</>
              </Dropdown.Toggle>
            </div>
            <Dropdown.Menu>
              {availableRegions?.map(({ region }) => (
                <Dropdown.Item key={region} eventKey={region}><img height={25} alt='' src={require(`../../assets/flags/${countryCode2flag[region]}.png`)} /> {countryCode2name[region]}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>


        <Form.Group controlId="dropdown3" className='mb-3'>
          <Form.Label><b>Challenge</b></Form.Label>
          <Form.Control as="select" onChange={(e) => handleDropdownChange(e, 3)}>
            <option value="count">Quantity</option>
            <option value="hight">Height</option>
            <option value="bounty">Bounty</option>
          </Form.Control>
        </Form.Group>

        <Link to={'/rankings'} state={{ 'challenge': selectedChallenge, 'region': selectedRegion, 'period': selectedPeriod, 'type': 'current' }} className="btn btn-primary">
          Show
        </Link>
      </Form>











      <FloatingMapButton />
    </>
  );
};

export default RankingsPre;