import { useRef, useState, useEffect } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import { countryCode2name, countryCode2flag, challengeCode2Name, periodCode2Name } from "../constants";
import { ArrowLeft } from 'react-bootstrap-icons';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';

function Rankings() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [dataRankings, setDataRankings] = useState(null);
    const userItemRef = useRef(null);
    // console.log(state)

    useEffect(() => {
        let url_part;
        if (state.type === 'finished') {
            url_part = `/v2/api/rankings_finished/?challenge=${state.challenge}&region=${state.region}&period=${state.period}&period_type=${state.period_type}`
        } else {
            url_part = `/v2/api/rankings/?challenge=${state.challenge}&region=${state.region}&period=${state.period}`
        }
        axios.get(process.env.REACT_APP_BACKEND_URL + url_part)
            .then(response => {
                // Handle success
                // console.log('response', response.data); // Log the data
                setDataRankings(response.data);
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }, []);


    useEffect(() => {
        // Scroll to the desired item when the component mounts
        if (userItemRef.current) {
            userItemRef.current.scrollIntoView(false);
        }
    }, [dataRankings]);


    return (
        <>
            <div className="navbarmargin">
                {/* // challenge name and back button */}
                <div>
                    <ArrowLeft style={{ position: 'absolute', margin: '5px', color: 'blue' }} size={30} onClick={() => navigate(-1)} />


                    <div className="p-2 bg-light text-dark fw-bold">
                        <div className='text-center'>Challenge {challengeCode2Name[state.challenge]}</div>
                    </div>
                </div>
                {/* // standings info */}
                <Container className="mb-3">

                    <div className='row'>
                        <div className="col-6">
                            <b>Period:</b> {state.type === 'finished' ? state.win_period : periodCode2Name[state.period]}
                        </div>
                        <div className="col-6 text-end">
                            <b>Region:</b> {countryCode2name[state.region]}
                        </div>
                    </div>
                </Container>
                {/* // header */}
                <div className="row mx-1 fw-bold">
                    <div className="col-2">
                        Position
                    </div>
                    <div className="col-3">
                    </div>
                    <div className="col-4 ps-0">
                        Username
                    </div>
                    <div className="col-3 text-end">
                        Score
                    </div>
                </div>
            </div>
            <div style={{ height: 'calc(100vh - 160px)', overflowY: 'scroll' }}>
                {dataRankings ?
                    <ListGroup>
                        {dataRankings.rankings.map((user, index) => (
                            <Link key={user.username} to={'/profile'} state={{ 'username': user.username }} style={{ textDecoration: 'none', color: 'inherit' }}>

                                {user.score > 0 && <ListGroup.Item className={user.username === localStorage.getItem('username') ? "bg-info" : ''} ref={user.username === localStorage.getItem('username') ? userItemRef : null}>
                                    {user.rank_curr === 1 ? <div className="position-absolute top-0 start-0" style={{ height: '100%', width: '10px', backgroundColor: 'gold' }}></div> :
                                        user.rank_curr === 2 ? <div className="position-absolute top-0 start-0" style={{ height: '100%', width: '10px', backgroundColor: 'silver' }}></div> :
                                            user.rank_curr === 3 ? <div className="position-absolute top-0 start-0" style={{ height: '100%', width: '10px', backgroundColor: '#cd7f32' }}></div> :
                                                <div></div>

                                    }
                                    <div className="row d-flex align-items-center">
                                        <div className="col-2">
                                            {user.rank_curr}
                                        </div>
                                        <div className="col-3">
                                            <img alt='' height={30} src={require(`../../assets/flags/${countryCode2flag[user.user_settings__region]}.png`)} />
                                        </div>
                                        <div className="col-4 text-capitalize px-0">
                                            {user.username}
                                        </div>
                                        <div className="col-3 text-end">
                                            {user.score.toFixed(2)}
                                        </div>
                                    </div>

                                </ListGroup.Item>}
                            </Link>
                        ))}
                    </ListGroup>
                    :
                    <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>
                }
            </div>
        </>
    );


}

export default Rankings;