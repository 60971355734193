import { db } from "./db";
import axios from 'axios';
import Dexie from 'dexie';

// send locally captured hills to the server
export const sendLocalCapturedHills = async () => {
    const capHillsLocally = await db.captures.toArray();
    if (capHillsLocally.length > 0) {
        axios.post(process.env.REACT_APP_BACKEND_URL + `/v2/api/submit_captured_hills/`, {
            "hills": capHillsLocally
        })
            .then(async function (response) {
                const acceptedIds = response.data.accepted
                const rejectedIds = response.data.rejected
                const processedIds = [...acceptedIds, ...rejectedIds]
                await db.captures.where("hill_id").anyOf(processedIds).delete();
                console.log('locally captured hills synced')
            })
            .catch(function (error) {
                console.log(error);
            });
    }

};

// pull captured hills form the server for logged in user and update it in local db
export const pullAndInsertCapturedHills = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/hillscaptured/?u=${localStorage.getItem('username')}`);
        const hills = response.data.hills;
        const hill_ids = hills.map((hill) => hill.hill_id);
        await updateCapturedHills(hill_ids); // Wait for updateCapturedHills to finish
        // Execute the next line of code here
    } catch (error) {
        console.error(error);
    }
};


// used to insert captured hills from server into local db
const updateCapturedHills = async (hill_ids) => {
    // hill_ids - list of hill ids
    await db.transaction("rw", db.hills, async () => {
        await db.hills.where("captured").equals(1).modify({ captured: 0 });
        await db.hills.where("hill_id").anyOf(hill_ids).modify({ captured: 1 });
        console.log('captured hills updated')
    }).catch(Dexie.ModifyError, error => {
        console.error(error.failures.length + " items failed to modify");
    }).catch(error => {
        console.error("Generic error: " + error);
    });
}

// used to insert bounty hills from server into local db
export const pullAndInsertBountyHills = async (donwloaded_regions) => {
    // donwloaded_regions - list of region codes
    // const donwloaded_regions = JSON.parse(localStorage.getItem('downloaded_regions'));
    // console.log('donwloaded_regions', donwloaded_regions.join(','));
    if (donwloaded_regions?.length > 0) {
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/bountyhills/?regions=${donwloaded_regions.join(',')}`);
            const hill_ids = response.data.bounties.map((hill) => hill.hill_id);
            await updateBountyHills(hill_ids);
            // Execute the next line here
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
};

// insert bounty hills to local db
async function updateBountyHills(hill_ids) {
    try {
        await db.transaction("rw", db.hills, async () => {
            await db.hills.where("bounty").equals(1).modify({ bounty: 0 });
            await db.hills.where("hill_id").anyOf(hill_ids).modify({ bounty: 1 });
            console.log('Bounties updated');
        });
    } catch (error) {
        if (error instanceof Dexie.ModifyError) {
            console.error(error.failures.length + " items failed to modify");
        } else {
            console.error("Generic error: " + error);
        }
    }
}