import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';

function ResetPage() {

    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);



    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();

        const formData = new FormData();
        formData.append('email', email);

        try {
            // Replace with your server endpoint
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + `/accounts/password-reset/`, formData);
            console.log('response', response);
            console.log('response data', response.data);
            if (response.data.errors) {
                console.log('errors!');
                setErrorMsg(response.data.errors)
            } else {
                console.log('success!');
                navigate('/login', { state: { msg: 'We have emailed you instructions for setting your password. You should receive the email shortly!' } })


            }
        } catch (error) {
            console.log('catch!');
            console.error('Error sending POST request:', error);
            setErrorMsg('Unknown error. Are you sure you have internet connection?')
        }
        setIsLoading(false)



    };

    return (
        <>
            <div className='navbarmargin'></div>

            <Container>
                <h2 className="text-center mt-4">Password Reset</h2>
                {errorMsg && <Alert variant='danger'>{errorMsg}</Alert>}

                <Form onSubmit={handleSubmit}>

                    <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                        />
                    </Form.Group>


                    <Button variant="primary" type="submit" className="w-100" disabled={isLoading}>
                        {isLoading && <Spinner as="span" size="sm" />}
                        Reset Password
                    </Button>
                </Form>
                <div className="d-flex mt-3">
                    <div>Already registered?</div>
                    <div className="ms-2"><Link to={'/login'}>Login</Link></div>
                </div>
            </Container>


        </>
    );


}

export default ResetPage;