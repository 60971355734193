import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import FloatingMapButton from '../FloatingMapButton';

const Challenges = () => {


    return (
        <>
            <div className='navbarmargin'></div>

            <Container style={{ fontSize: '14px' }}>
                <div className='row'>
                    <div className='col-6 px-2'>
                        <Link to={'/challengestandings'} state={{ 'challenge': 'count' }} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="card card-body my-2 text-center">
                                <div>
                                    <svg
                                        width="50"
                                        height="50"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="gray"
                                            d="M1,5h2v14L1,19zM5,5h2v14L5,19zM22,5L10,5c-0.55,0 -1,0.45 -1,1v12c0,0.55 0.45,1 1,1h12c0.55,0 1,-0.45 1,-1L23,6c0,-0.55 -0.45,-1 -1,-1zM11,17l2.5,-3.15L15.29,16l2.5,-3.22L21,17L11,17z"
                                        />
                                    </svg>
                                </div>
                                <b>Challenge Quantity</b>
                                <hr className="border-2 border-top my-1 bg-secondary" />
                                Capture as many hills as possible
                            </div>
                        </Link>
                    </div>
                    <div className='col-6 px-2'>
                        <Link to={'/challengestandings'} state={{ 'challenge': 'hight' }} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="card card-body my-2 text-center">
                                <div>
                                    <svg
                                        width="50"
                                        height="50"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="gray"
                                            d="M17,20.41L18.41,19 15,15.59 13.59,17 17,20.41zM7.5,8H11v5.59L5.59,19 7,20.41l6,-6V8h3.5L12,3.5 7.5,8z"
                                        />
                                    </svg>
                                </div>
                                <b>Challenge Height</b>
                                <hr className="border-2 border-top my-1 bg-secondary" />
                                Capture higher hills to get more points
                            </div>
                        </Link>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-6 px-2'>
                        <Link to={'/challengestandings'} state={{ 'challenge': 'bounty' }} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="card card-body my-2 text-center">
                                <div>
                                    <svg
                                        width="50"
                                        height="50"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="gray"
                                            d="M11.99,2C6.47,2 2,6.48 2,12s4.47,10 9.99,10C17.52,22 22,17.52 22,12S17.52,2 11.99,2zM16.23,18L12,15.45 7.77,18l1.12,-4.81 -3.73,-3.23 4.92,-0.42L12,5l1.92,4.53 4.92,0.42 -3.73,3.23L16.23,18z"
                                        />
                                    </svg>
                                </div>
                                <b>Challenge Bounty</b>
                                <hr className="border-2 border-top my-1 bg-secondary" />
                                Capture special hills to get points
                            </div>
                        </Link>

                    </div>

                    <div className='col-6 px-2'>
                        <Link to={'/challengefinished'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="card card-body my-2 text-center">
                                <div>
                                    <svg
                                        width="50"
                                        height="50"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="gray"
                                            d="M19,3h-4.18C14.4,1.84 13.3,1 12,1c-1.3,0 -2.4,0.84 -2.82,2L5,3c-1.1,0 -2,0.9 -2,2v14c0,1.1 0.9,2 2,2h14c1.1,0 2,-0.9 2,-2L21,5c0,-1.1 -0.9,-2 -2,-2zM12,3c0.55,0 1,0.45 1,1s-0.45,1 -1,1 -1,-0.45 -1,-1 0.45,-1 1,-1zM14,17L7,17v-2h7v2zM17,13L7,13v-2h10v2zM17,9L7,9L7,7h10v2z"
                                        />
                                    </svg>
                                </div>
                                <b>Finished Challenges</b>
                                <hr className="border-2 border-top my-1 bg-secondary" />
                                Check results of the finished challenges
                            </div>
                        </Link>
                    </div>

                </div>
            </Container>
            <FloatingMapButton />




        </>
    );
};

export default Challenges;