import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';
import axios from 'axios';
import { useState, useEffect } from "react";
import { countryCode2name, countryCode2flag, challengeCode2Name } from "../constants";


const ChallengeStandings = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    // console.log('challenges state', state)
    const [standings, setStandings] = useState(null);


    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/standing/?u=${localStorage.getItem('username')}`)
            .then(response => {
                // Handle success
                const standings_life = preprocessStandingsResponse(response.data, 'life', state.challenge)
                const standings_year = preprocessStandingsResponse(response.data, 'year', state.challenge)
                const standings_quarter = preprocessStandingsResponse(response.data, 'quarter', state.challenge)
                const ordered_standings = { 'life': standings_life, 'year': standings_year, 'quarter': standings_quarter }
                // console.log('ordered_standings', ordered_standings)
                setStandings(ordered_standings);
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }, []);


    // reorder standings, first one will be world, rest ordered by country
    function preprocessStandingsResponse(responseData, period_type, challenge) {
        // filter data from response for chosen challenge and period
        const standings_period = responseData.standings.filter(
            (entry) =>
                entry.challenge === challenge &&
                entry.period_type === period_type
        );
        // Filter the data for region 'world'
        const worldData = standings_period.find(item => item.region === 'world');

        // Create a shallow copy of the remaining data and sort by region
        const sortedData = [...standings_period]
            .filter(item => item.region !== 'world')
            .sort((a, b) => a.region.localeCompare(b.region));

        // Combine the filtered and sorted data
        const combinedData = [worldData, ...sortedData];
        return combinedData;
    }


    const StandingsCard = (props) => {
        const standing = props.standing
        const period = props.period
        return (
            <Link to={'/rankings'} state={{ 'challenge': state.challenge, 'region': standing.region, 'period': period }} style={{ textDecoration: 'none', color: 'inherit' }}>

                <div className="card card-body m-2 text-center" style={{ fontSize: '14px' }}>
                    <div className="row">
                        <div className='col-6 text-start'>
                            <div>{standing.region === 'world' ? 'World' : 'Country'} ranking</div>
                            <div>{standing.region !== 'world' && countryCode2name[standing.region]}</div>
                        </div>
                        <div className='col-6 d-flex justify-content-end align-items-center'>
                            <img
                                src={require(`../../assets/flags/${countryCode2flag[standing.region]}.png`)}
                                width="40px"
                                alt='' />
                        </div>
                    </div>
                    <hr className="border-2 border-top my-1 bg-secondary" />

                    <div className='row'>
                        <div className='col-4'>
                            Curent Rank
                        </div>
                        <div className='col-4'>
                            Highest Rank
                        </div>
                        <div className='col-4'>
                            Score
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            {standing.rank_curr}
                        </div>
                        <div className='col-4'>
                            {standing.rank_best}
                        </div>
                        <div className='col-4'>
                            {standing.score.toFixed(2)}
                        </div>
                    </div>
                </div>
            </Link>
        )
    }


    if (!standings) {
        return (
            <>
              <div className='navbarmargin'></div>
              <span>Loading...</span>
            </>
          )
    }

    return (
        <>
            <div className='navbarmargin'></div>

            <div>
                <ArrowLeft style={{ position: 'absolute', margin: '5px', color: 'blue' }} size={30} onClick={() => navigate(-1)} />
                <div className="p-2 bg-light text-dark fw-bold">
                    <div className='text-center'>Challenge {challengeCode2Name[state.challenge]}</div>
                </div>
            </div>

            <h3 className='text-center'>Lifetime Standings</h3>
            {standings['life'].map((standing, index) => (
                <StandingsCard key={index} standing={standing} period='life' />
            ))}

            <h3 className='text-center'>Year Standings</h3>
            {standings['year'].map((standing, index) => (
                <StandingsCard key={index} standing={standing} period='year' />
            ))}

            <h3 className='text-center'>Quarter Standings</h3>
            {standings['quarter'].map((standing, index) => (
                <StandingsCard key={index} standing={standing} period='quarter' />
            ))}
        </>
    );
};

export default ChallengeStandings;