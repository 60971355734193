import Dexie from 'dexie';

export const db = new Dexie('myDatabase');

// downlaoded hills
db.version(1).stores({
  hills: '++id, &hill_id, region, latitude, longitude, bounty, captured' // Primary key and indexed props
});

// captured hills temporary
db.version(1).stores({
  captures: '++id, &hill_id' // Primary key and indexed props
});