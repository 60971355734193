import { countryCode2name, countryCode2flag } from "../constants";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { ArrowLeft } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';

const FinishedChallenges = () => {
    // const { state } = useLocation();
    const navigate = useNavigate();
    const [selectedWinPeriod, setSelectedWinPeriod] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedChallenge, setSelectedChallenge] = useState('count');
    const [dataDropdowns, setDataDropdowns] = useState(null);
    const [availableRegions, setAvailableRegions] = useState(null);
    const [availableWinPeriods, setAvailableWinPeriods] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/fin_challs_dropdowns/`)
            .then(response => {
                // Handle success
                // create win_period field
                const dataWithWinPeriod = response.data.dropdowns.map(item => {
                    let combinedPeriod;
                    if (item.period_type === 'life' || item.period_type === 'year') {
                        combinedPeriod = `${item.period}-${item.period_type}`;
                    } else {
                        combinedPeriod = item.period;
                    }
                    return { ...item, win_period: combinedPeriod };
                });
                const uniqueWinPeriods = [...new Set(dataWithWinPeriod.map(item => item.win_period))];
                setAvailableWinPeriods(uniqueWinPeriods)
                const sortedData = dataWithWinPeriod.sort((a, b) => countryCode2name[a.region].localeCompare(countryCode2name[b.region]));
                setDataDropdowns(sortedData);
                setSelectedWinPeriod(uniqueWinPeriods[0])
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }, []);

    // set region dropdown based on selected period
    useEffect(() => {
        const available_regions = dataDropdowns?.filter(
            (entry) => entry.win_period === selectedWinPeriod)
        setAvailableRegions(available_regions)
        setSelectedRegion('world')
    }, [dataDropdowns, selectedWinPeriod]);


    const handleSubmit = (event) => {
        event.preventDefault();
        // get original period and period_type
        const selectedEntry = dataDropdowns.filter(
            (entry) =>
                entry.region === selectedRegion &&
                entry.win_period === selectedWinPeriod
        )[0];
        navigate('/rankings', { state: { 'challenge': selectedChallenge, 'region': selectedRegion, 'period': selectedEntry.period, 'period_type': selectedEntry.period_type, 'win_period': selectedWinPeriod, 'type': 'finished' } })
    };

    const handleDropdownChange = (event, dropdownNumber) => {
        const selectedValue = event.target.value;
        switch (dropdownNumber) {
            case 1:
                setSelectedWinPeriod(selectedValue);
                break;
            // case 2:
            //     setSelectedRegion(selectedValue);
            //     break;
            case 3:
                setSelectedChallenge(selectedValue);
                break;
            default:
                break;
        }
    };


    if (!dataDropdowns) {
        return (
            <>
              <div className='navbarmargin'></div>
              <span>Loading...</span>
            </>
          )
    }

    return (
        <>
            <div className='navbarmargin'></div>

            <div>
                <ArrowLeft style={{ position: 'absolute', margin: '5px', color: 'blue' }} size={30} onClick={() => navigate(-1)} />


                <div className="p-2 bg-light text-dark fw-bold">
                    <div className='text-center'>Finished Challenges</div>
                </div>
            </div>

            <Form onSubmit={handleSubmit} className='m-2'>
                <Form.Group controlId="dropdown1" className='mb-3'>
                    <Form.Label><b>Period</b></Form.Label>
                    <Form.Control as="select" onChange={(e) => handleDropdownChange(e, 1)}>
                        {availableWinPeriods?.map((entry) => (
                            <option key={entry} value={entry}>
                                {entry}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>


                <Form.Group controlId="dropdown2" className='mb-3'>
                    <Form.Label><b>Region</b></Form.Label>
                    <Dropdown
                        onSelect={eventKey => {
                            // const { region } = availableRegions.find(({ region }) => eventKey === region);
                            setSelectedRegion(eventKey);
                        }}
                    >
                        <div className='form-control p-0'>
                            <Dropdown.Toggle variant="light" id="dropdown-flags" className="text-start bg-white" style={{ width: '100%' }}>
                                <><img alt='' height={25} src={require(`../../assets/flags/${countryCode2flag[selectedRegion]}.png`)} /> {countryCode2name[selectedRegion]}</>
                            </Dropdown.Toggle>
                        </div>
                        <Dropdown.Menu>
                            {availableRegions?.map(({ region }) => (
                                <Dropdown.Item key={region} eventKey={region}><img alt='' height={25} src={require(`../../assets/flags/${countryCode2flag[region]}.png`)} /> {countryCode2name[region]}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>


                <Form.Group controlId="dropdown3" className='mb-3'>
                    <Form.Label><b>Challenge</b></Form.Label>
                    <Form.Control as="select" onChange={(e) => handleDropdownChange(e, 3)}>
                        <option value="count">Quantity</option>
                        <option value="hight">Height</option>
                        <option value="bounty">Bounty</option>
                    </Form.Control>
                </Form.Group>


                <Button variant="primary" type="submit">
                    Show
                </Button>
            </Form>


        </>
    );
};

export default FinishedChallenges;