import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { countryCode2name, countryCode2flag, countryCodes } from "../constants";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import FloatingMapButton from "../FloatingMapButton";
import axios from 'axios';
// import Toast from 'react-bootstrap/Toast';
// import ToastContainer from 'react-bootstrap/ToastContainer';

function AccountSettings(props) {

    const [countryCode, setCountryCode] = useState(null);
    const [dataUserProfile, setDataUserProfile] = useState(null);
    // const [showToast, setShowToast] = useState(true);

    const navigate = useNavigate();

    


    const handleRegionDropdownChange = (event) => {
        const selectedValue = event.target.value;
        sendSettingsToServer(selectedValue)
    };

    const handleLogoutClick = () => {
        navigate('/login')
        localStorage.removeItem("access_token");
        props.setIsLoggedIn(false)
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/usersettings/?u=${localStorage.getItem('username')}`)
            .then(response => {
                // Handle success
                setDataUserProfile(response.data);
                setCountryCode(response.data.userdata[0].region)
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }, []);


    const sendSettingsToServer = async (region) => {
        // current utc time
        const currentDateTime = new Date();
        const formattedDateTime = currentDateTime.toISOString().slice(0, 19).replace('T', ' ');
        await axios.post(process.env.REACT_APP_BACKEND_URL + `/v2/api/change_flag/`, {
            "region": region, "last_change": formattedDateTime
        })
            .then(function (response) {
                // console.log('response.data', response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        setCountryCode(region)
        localStorage.setItem('user_region', region);

    };


    if (!countryCode) {
        return (
            <>
                <div className='navbarmargin'></div>
                <span>Loading...</span>
            </>
        )
    }


    return (
        <>
            <Container className="navbarmargin">
                <div className="my-2 fw-bold">Username</div>
                <div className="my-2 text-capitalize">{dataUserProfile.userdata[0].username}</div>
                <hr className="border-2 border-top my-1 bg-secondary" />

                <div className="my-2 fw-bold">Email Address</div>
                <div className="my-2">{dataUserProfile.userdata[0].email}</div>
                <hr className="border-2 border-top my-1 bg-secondary" />

                <div className="my-2 fw-bold">Version</div>
                <div className="my-2">v{process.env.REACT_APP_VERSION}</div>
                <hr className="border-2 border-top my-1 bg-secondary" />

                <Form.Group controlId="dropdown2" className='mb-3'>
                    <Form.Label><b>Country</b></Form.Label>
                    <Form.Control as="select" onChange={(e) => handleRegionDropdownChange(e)} value={countryCode}>
                        {countryCodes?.map((country_code) => (
                            <option key={country_code} value={country_code}>
                                {countryCode2name[country_code]}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <div className="my-2 text-center">
                    <img alt='' src={require(`../../assets/flags/${countryCode2flag[countryCode]}.png`)} />
                </div>



                <button type="button" className="btn btn-primary w-100" onClick={() => handleLogoutClick()}>
                    Logout
                </button>



            </Container>
            <FloatingMapButton />


            {/* <ToastContainer
                className="p-3"
                position='bottom-center'
            >
                <Toast
                    show={showToast}
                    onClose={() => setShowToast(false)}
                // delay={5000} autohide
                >
                    <Toast.Header closeButton={true}>
                        <strong className="me-auto">Install this app</strong>
                    </Toast.Header>
                    <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
                </Toast>
            </ToastContainer> */}


         





        </>
    );


}

export default AccountSettings;