import { Link } from 'react-router-dom'; 
import { GeoAltFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

function FloatingMapButton() {
    return (
      <>
      <Link to="/map" >
      <Button variant="primary" className="floating-button-right rounded-circle p-0"  style={{  width: '42px', height: '42px' }}><GeoAltFill size={25}/></Button>
      </Link>
      </>
    );
  }
  
  export default FloatingMapButton;