import { useState } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { db } from "../db";
import { countryCode2name, countryCode2flag, subCode2RegionName } from "../constants";
import FloatingMapButton from "../FloatingMapButton";
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';

function DownloadedHills() {
    // localStorage.removeItem("downloaded_regions")
    const [regions, setRegions] = useState(JSON.parse(localStorage.getItem('downloaded_regions')));
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeletingRegion, setIsDeletingRegion] = useState(null);

    console.log(`downloaded regions from storage ${regions}`)

    async function deleteHills(region_code) {
        setIsDeleting(true)
        setIsDeletingRegion(region_code)
        try {
            await db.hills.where('region').equals(region_code).delete();
            console.log('hills deleted successfully!');
            const updatedList = regions.filter((item) => item !== region_code);
            console.log(`updatedList`, updatedList)
            localStorage.setItem('downloaded_regions', JSON.stringify(updatedList));
            console.log(`updatedList saved in local storage`, updatedList)
            setRegions(updatedList)
        } catch (error) {
            console.error('Error deleting hills:', error);
        }
        setIsDeleting(false)
    };



    return (
        <>
            <div className='navbarmargin'></div>

            <div className="p-2 bg-light text-dark text-center">Downloaded Capturable Hills</div>
            <ListGroup>
                {regions?.map((region_code) => (
                    <ListGroup.Item key={region_code} >
                        <div className="row d-flex align-items-center">
                            <div className="col-3">
                                <img alt='' src={require(`../../assets/flags/${countryCode2flag[region_code.substring(0, 3)]}.png`)} />
                            </div>
                            <div className="col-5">
                                {region_code.substring(0, 3) === 'USA' ? subCode2RegionName[region_code] : countryCode2name[region_code]}
                            </div>
                            <div className="col-4 text-end">
                                {isDeleting && isDeletingRegion === region_code ?
                                    <Button variant="danger" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button> :
                                    <Button variant="danger" disabled={isDeleting} onClick={() => deleteHills(region_code)}><Trash /></Button>}
                            </div>
                        </div>

                    </ListGroup.Item>
                ))}
            </ListGroup>

            <Link to="/hilldownload" >
                <Button variant="primary" className="floating-button-left rounded-circle p-0" style={{ width: '42px', height: '42px' }}><PlusCircle size={25} /></Button>
            </Link>

            <FloatingMapButton />
        </>
    );


}

export default DownloadedHills;