import Container from 'react-bootstrap/Container';
import '../styles/main_page.css';
import { useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Android2, Apple, Facebook, Instagram, TwitterX, Backpack2, Phone, PinMap, BarChart } from 'react-bootstrap-icons';
import googlePlayImg from '../assets/google-play-badge.png'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

function MainPage() {

    function AndroidDeviceCard() {
        return (
            //   <Card style={{ width: '18rem' }}>
            <Card style={{ height: '100%', backgroundColor: '#f8f9fa' }}>
                {/* <Card.Img variant="top" src="android-logo.png" alt="Android Logo"/> */}
                <div className="justify-content-center mt-2">
                    <Android2 size={64} />
                </div>
                <Card.Body>
                    <Card.Title>Android Device</Card.Title>
                    <Card.Text>
                        Get android version from Google Play
                    </Card.Text>
                    <Card.Img variant="bottom" src={require("../assets/img/mobil_hh_vertical2.png")} alt=""/>
                    <a href="https://play.google.com/store/apps/details?id=com.hh.hill_hunter&hl=sk&gl=US"><img src={googlePlayImg} width="170"></img></a>
                </Card.Body>
            </Card>
        );
    }


    function IosDeviceCard() {
        return (
            //   <Card style={{ width: '18rem' }}>
            <Card style={{ height: '100%', backgroundColor: '#f8f9fa' }}>
                {/* <Card.Img variant="top" src="android-logo.png" alt="Android Logo"/> */}
                <div className="justify-content-center mt-2">
                    <Apple size={64} />
                </div>
                <Card.Body>
                    <Card.Title>iOS Device</Card.Title>
                    <Card.Text>
                        Use web browser version for iOS devices. Add shortcut to your home screen.
                    </Card.Text>
                    <Card.Img variant="bottom" src={require("../assets/img/ios_install.png")} alt="" />
                    <Link to={'/login'}>
                        <Button className='mt-2' variant="primary">Get Started</Button>
                    </Link>
                </Card.Body>
            </Card>
        );
    }


    return (
        <>


            {/* <!-- Masthead--> */}
            <header className="masthead" id='page-top'>
                <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                    <div className="d-flex justify-content-center">
                        <div className="text-center">
                            <h1 className="mx-auto my-0 text-uppercase">Hill Hunter GPS</h1>
                            <h2 className="text-white-50 mx-auto mt-2 mb-5">Experience new hiking challenges.</h2>
                            <Link to={'/login'}>
                                <Button className='mt-2' variant="primary">Get Started</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- About--> */}
            <section className="about-section text-center" id="about">
                <div className="container px-4 px-lg-5">
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <div className="col-lg-8">
                            <h2 className="text-white mb-4">What is Hill Hunter GPS?</h2>
                            <p className="text-white-50">
                                Hill Hunter GPS is a location-based game for hiking enthusiasts. The goal is to earn points for visiting and capturing hills, mountains, and other real-life locations. There are several challenges that are evaluated annually and quarterly. You can win rewards in the form of trophies. The application will monitor your lifetime hiking progress, and you can compare your standings with other users in your country and around the world.
                                There are more than 600,000 target hills and locations worldwide.
                                <br />
                                <br />
                                Do not stay at home, GO HIKE!
                            </p>
                        </div>
                    </div>
                    {/* <img className="img-fluid" src={require("../assets/img/ipad.png")} alt="..." /> */}
                    <img className="img-fluid" src={require("../assets/img/mobil_hh4.png")} alt="..." />
                </div>
            </section>

            {/* // how it works section */}
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center text-center mb-4">
                        <div className="col-lg-8 col-xxl-7">
                            <h2 className="display-5 fw-bold">How it Works</h2>
                            <p className="lead">Follow these steps after registration to earn points.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="text-center position-relative">
                                <div className="step-icon mx-auto bg-primary border rounded-circle d-flex align-items-center justify-content-center" style={{
                                    width: '120px',
                                    height: '120px',
                                }}>
                                    <Backpack2 size={30} style={{ color: 'white' }} />
                                    {/* <svg className="bi bi-basket text-white" fill="currentColor" height="40" viewBox="0 0 16 16" width="40" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z"></path></svg> */}
                                </div>
                                <h4 className="mt-3 fs-5">Step 1</h4>
                                <p className="lead text-muted mt-4 fs-5 px-lg-3 mb-5 mb-lg-0">Go to the target place.</p>
                                <div className="arrow-icon position-absolute d-none d-lg-block" style={{
                                    top: '50px',
                                    right: '-25px',
                                }}>
                                    <svg className="bi bi-arrow-right" fill="currentColor" height="30" viewBox="0 0 16 16" width="30" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fillRule="evenodd"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="text-center position-relative">
                                <div className="step-icon mx-auto bg-primary border rounded-circle d-flex align-items-center justify-content-center" style={{
                                    width: '120px',
                                    height: '120px',
                                }}>
                                    <Phone size={30} style={{ color: 'white' }} />
                                </div>
                                <h4 className="mt-3 fs-5">Step 2</h4>
                                <p className="lead text-muted mt-4 fs-5 px-lg-3 mb-5 mb-lg-0">Open the Hill Hunter GPS application.</p>
                                <div className="arrow-icon d-none d-lg-block position-absolute" style={{
                                    top: '50px',
                                    right: '-25px',
                                }} >
                                    <svg className="bi bi-arrow-right" fill="currentColor" height="30" viewBox="0 0 16 16" width="30" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fillRule="evenodd"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="text-center position-relative">
                                <div className="step-icon mx-auto bg-primary border rounded-circle d-flex align-items-center justify-content-center" style={{
                                    width: '120px',
                                    height: '120px',
                                }}>
                                    <PinMap size={30} style={{ color: 'white' }} />
                                </div>
                                <h4 className="mt-3 fs-5">Step 3</h4>
                                <p className="lead text-muted mt-4 fs-5 px-lg-3 mb-5 mb-lg-0">Manually select and capture the place on the map in the application.</p>
                                <div className="arrow-icon d-none d-lg-block position-absolute" style={{
                                    top: '50px',
                                    right: '-25px',
                                }}>
                                    <svg className="bi bi-arrow-right" fill="currentColor" height="30" viewBox="0 0 16 16" width="30" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fillRule="evenodd"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="text-center position-relative">
                                <div className="step-icon mx-auto bg-primary border rounded-circle d-flex align-items-center justify-content-center" style={{
                                    width: '120px',
                                    height: '120px',
                                }}>
                                    <BarChart size={30} style={{ color: 'white' }} />
                                </div>
                                <h4 className="mt-3 fs-5">Step 4</h4>
                                <p className="lead text-muted mt-4 fs-5 px-lg-3 mb-5 mb-lg-0">Check your rankings and win trophies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Get App section--> */}
            <section className="getapp-section text-center py-5" id="getapp">
                <div className="container">
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <h2 className="text-white mb-4">Get the application</h2>
                        <div className='row'>
                            <div className='col-md-6'>
                                <AndroidDeviceCard />
                            </div>
                            <div className='col-md-6 '>
                                <IosDeviceCard />

                            </div>

                        </div>

                    </div>
                </div>
            </section>


            {/* <!-- About us--> */}
            <section className="projects-section bg-light">
                <div className="container px-4 px-lg-5">
                    {/* <!-- Featured Project Row--> */}
                    <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
                        <div className="col-xl-8 col-lg-7"><img className="img-fluid mb-3 mb-lg-0" src={require("../assets/img/demo-image-02.jpg")} alt="..." /></div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="featured-text text-center text-lg-left">
                                <h4>About Us</h4>
                                <p className="text-black-50 mb-0">Hill Hunter GPS was created in 2020 by a group of hiking enthusiasts. Our goal is to develop a useful mobile application that motivates people to visit nature more frequently and explore the world. The app has already helped us discover new adventures by taking us to places we wouldn’t normally visit. We believe it can be even more enjoyable by creating larger hiking community.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* <!-- FAQ--> */}
            <div className="container px-4 px-lg-5 mb-4" id='faq'>
                <h2 className=" fw-bold text-center">Frequently Asked Questions</h2>
                {/* <p className="lead">You will do below steps after the registration.</p> */}
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Is it free?</Accordion.Header>
                        <Accordion.Body>
                            Hill Hunter GPS is free and will remain free indefinitely.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Which places or hills can I capture?</Accordion.Header>
                        <Accordion.Body>
                            There are predefined target places in the Hill Hunter GPS application. There are more than 600 000 target places around the world. You can check them within the app.
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </div>

            {/* <!-- Footer--> */}
            <footer className="footer bg-black small text-center text-white-50">
                <div className="container px-4 px-lg-5">
                    <div className='row'>
                        <div className='col'>
                            &copy; Hill Hunter GPS 2020-{new Date().getFullYear()}
                        </div>
                        <div className='col' >
                            <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.facebook.com/HillHunterGPS"><Facebook size={30} className='me-3' /></a>
                            <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.instagram.com/hillhuntergps/"><Instagram size={30} className='me-3' /></a>
                            <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://twitter.com/HillHunterGPS"><TwitterX size={30} className='me-3' /></a>
                        </div>

                    </div>


                </div>

            </footer>
            {/* <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script> */}
            {/* <script src="js/scripts.js"></script> */}
            {/* <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script> */}

        </>
    );
}

export default MainPage;