import { useRef, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, useMap, ScaleControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { icon } from '../constants'


function HillsMap(props) {
    const mapRef = useRef(null);
    const navigate = useNavigate();

    // const { state } = useLocation();

    useEffect(() => {
        mapRef.current?.setView(props.mapCenter, 13)

    }, [props.mapCenter]);


    const ResizeMap = () => {
        const map = useMap();
        map._onResize();
        return null;
    };

    const handlePopupClick = (hill_id) => {
        // Your custom logic here when the popup is clicked
        // console.log('Popup clicked id', hill_id);
        navigate('/hillinfo', { state: { 'hill_id': hill_id } })
    };


    return (
        <>

            {/* // display height without navbar */}
            <div style={{ height: 'calc(100vh - 138px)', width: "100vw" }}>

                {/* // Make sure you set the height and width of the map container otherwise the map won't show */}
                <MapContainer center={[46, 16]} zoom={13} ref={mapRef} style={{ height: '100%' }} zoomControl={false}>
                    {/* // this is fix when tiles are not rendered properly */}
                    <ResizeMap />

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {props.dataCapturedHills?.map((hill, index) => (
                        <Marker key={hill.hill_id} position={[hill.latitude, hill.longitude]} icon={icon}>
                            <Popup>
                                <div onClick={() => { handlePopupClick(hill.hill_id) }}>
                                    <b>{hill.name}</b><br />
                                    {hill.hight} m<br />
                                    {hill.cap_date.slice(0, 16).replace(/T/g, ' ')} UTC
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                    <ScaleControl position="bottomleft" />
                </MapContainer>

            </div>
        </>
    );


}

export default HillsMap;