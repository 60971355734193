import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';

function LoginPage(props) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { state } = useLocation();
    const [authError, setAuthError] = useState(null);
    const [isLoggingIn, setIsLoggingIn] = useState(false);



    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        setIsLoggingIn(true)
        e.preventDefault();
        const user = {
            username: username,
            password: password
        };
        try {
            const uninterceptedAxiosInstance = axios.create();
            const { data } = await uninterceptedAxiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/v2/token/', user, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }, { withCredentials: true });
            // console.log('data', data)
            localStorage.setItem('access_token', data.access);
            localStorage.setItem('refresh_token', data.refresh);
            localStorage.setItem('username', username.toLowerCase());
            axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
            navigate('/home')
            props.setIsLoggedIn(true)
        } catch (error) {
            console.log('error', error)
            if (error.response?.data.detail) {
                // The request was made, but the server responded with an error status
                setAuthError(error.response.data.detail); // Display the error message
            }
            else if (error.response?.data.username) {
                // The request was made, but the server responded with an error status
                setAuthError(error.response.data.username[0]); // Display the error message
            } else {
                // Something went wrong (e.g., network error)
                setAuthError('An error occurred. Please try again later.');
            }
        };
        setIsLoggingIn(false)
    }

    return (
        <>
            <div className='navbarmargin'></div>

            <Container>
                <Alert variant='info'>Hill Hunter GPS requires access to your device’s location. Please accept the prompt when it appears.</Alert>

                <h2 className="text-center mt-4">Login</h2>
                {state && <Alert variant='warning'>{state.msg}</Alert>}
                {authError && <Alert variant='danger'>{authError}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter username"
                            value={username}
                            onChange={handleUsernameChange}
                            required 
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            required 
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100" disabled={isLoggingIn}>
                        {isLoggingIn && <Spinner as="span" size="sm" />}
                        Login
                    </Button>
                </Form>

                <div className="row mt-3">
                    <div className="col"><Link to={'/register'}>Create account?</Link></div>
                    <div className="col text-end"><Link to={'/reset'}>Forgotten password?</Link></div>
                </div>
            </Container>

        </>
    );


}

export default LoginPage;