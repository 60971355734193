import { useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation, useNavigate } from "react-router-dom";
import Trophies from '../other_tabs/Trophies'
import HillsList from "./HillsList";
import HillsMap from "./HillsMap";
import { ArrowLeft } from 'react-bootstrap-icons';
import axios from 'axios';

function Profile() {
    const { state } = useLocation();
    const navigate = useNavigate();
    // console.log('profile', state)

    const [activeTab, setActiveTab] = useState(1);
    const [dataCapturedHills, setDataCapturedHills] = useState([]);
    const [mapCenter, setMapCenter] = useState([55, 27.3]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/hillscaptured/?u=${state.username}`)
            .then(response => {
                // Handle success
                setDataCapturedHills(response.data.hills);
                setMapCenter([response.data.hills[0].latitude, response.data.hills[0].longitude])
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }, []);


    const handleHillJumpToMap = (hill_object) => {
        setMapCenter([hill_object.latitude, hill_object.longitude])
        setActiveTab(2);
    };


    const handleTabSelect = (key) => {
        setActiveTab(key);
    };


    return (
        <>
            <div className='navbarmargin'></div>

            <div >
                <ArrowLeft style={{ position: 'absolute', margin: '5px', color: 'blue' }} size={30} onClick={() => navigate(-1)} />
                <div className="p-2 bg-light text-dark fw-bold justify-content-center">
                    <div className='text-center text-capitalize'>{state.username}</div>
                </div>
            </div>



            <Tabs activeKey={activeTab} onSelect={handleTabSelect} id="noanim-tab-example" className="nav-pills nav-fill">
                <Tab eventKey={1} title="Trophies">
                    <Trophies />
                </Tab>
                <Tab eventKey={2} title="Hills Map">
                    <HillsMap mapCenter={mapCenter} dataCapturedHills={dataCapturedHills} />
                </Tab>
                <Tab eventKey={3} title="Hills List">
                    <HillsList dataCapturedHills={dataCapturedHills} handleHillJumpToMap={handleHillJumpToMap} />
                </Tab>
            </Tabs>


        </>
    );


}

export default Profile;