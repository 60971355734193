import React, { useState } from 'react';
import {  Button } from 'react-bootstrap';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

const ImageUploadForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleImageUpload = async ($event) => {
        setIsLoading(true)
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const formData = new FormData();
            formData.append('image', file);
            formData.append('hill_id', props.hill_id);

            try {
                // Replace with your server endpoint
                const response = await axios.post(process.env.REACT_APP_BACKEND_URL + `/v2/api/hill_image_upload/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    if (response.data.success) {
                        console.log('Image uploaded successfully!');
                        props.setToastMsg('Image uploaded successfully. Refresh page to see it.')
                        props.setShowToast(true)
                    } else {
                        console.log('Custom error uploading image');
                        props.setToastMsg(response.data.error)
                        props.setShowToast(true)
                    }
                    
                    // Handle any other logic (e.g., display a success message)
                } else {
                    console.error('Error uploading image');
                    props.setToastMsg('Error uploading image')
                    props.setShowToast(true)
                }
            } catch (error) {
                console.error('Error sending POST request:', error);
                props.setToastMsg('Error sending POST request')
                props.setShowToast(true)
            }
            setIsLoading(false)

        }

    }





    return (
        <>
            {isLoading ?
                <Button variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Uploading...
                </Button> :
                <div className="file btn btn-primary uploadbutton_div">
                    Upload Image
                    <input className="uploadbutton_input" type="file" name="file" accept="image/*" onChange={handleImageUpload} />
                </div>
            }

            
        </>
    );
};

export default ImageUploadForm;