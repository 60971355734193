import { useState, useEffect } from "react";
import { db } from "../db";
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { countryCode2flag } from "../constants";
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Download, Check2 } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { pullAndInsertBountyHills } from '../helpers'

function HillDownload() {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadingRegion, setIsDownloadingRegion] = useState(null);
    const [availableCounties, setAvailableCounties] = useState(null);
    const [downloadedRegions, setDownloadedRegions] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/regions1/`)
            .then(response => {
                // Handle success
                // console.log('response', response.data); // Log the data
                setAvailableCounties(response.data);
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });

    }, []);


    useEffect(() => {
        const retrievedRegions = localStorage.getItem('downloaded_regions');
        if (retrievedRegions !== null) {
            var parsedRegions = JSON.parse(retrievedRegions)
            setDownloadedRegions(parsedRegions)
        }
    }, []);

    async function addHills(region_code) {
        setIsDownloading(true)
        setIsDownloadingRegion(region_code)
        // dowload hills and insert to db
        await axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/hills/?region=${region_code}`)
            .then(async response => {
                // Handle success
                const hills_orig = response.data.hills
                // extract only mandatory fields
                const hills = hills_orig.map((item) => {
                    const { hill_id, longitude, latitude, region } = item;
                    return { hill_id, longitude, latitude, region };
                });
                // console.log('hills')
                // console.log(hills)
                await insertHillsData(hills, region_code)
                setIsDownloading(false)
            })
            .catch(error => {
                // Handle error
                console.error(error);
                setIsDownloading(false)

            });
        await pullAndInsertBountyHills([region_code])
    }


    async function insertHillsData(data, region_code) {
        try {

            await db.hills.bulkAdd(data);
            console.log(`hills successfully added.`)
            // const retrievedRegions = localStorage.getItem('downloaded_regions');
            // console.log(`downloaded regions from storage before ${retrievedRegions}`)
            // if (retrievedRegions === null) {
            //     localStorage.setItem('downloaded_regions', JSON.stringify([region_code]));
            // } else {
            //     var parsedRegions = JSON.parse(retrievedRegions)
            //     parsedRegions.push(region_code)
            //     localStorage.setItem('downloaded_regions', JSON.stringify(parsedRegions));
            //     console.log('downloaded regions from storage after', localStorage.getItem('downloaded_regions'))
            // }
            var downloadedRegions_update = [...downloadedRegions]
            downloadedRegions_update.push(region_code)
            localStorage.setItem('downloaded_regions', JSON.stringify(downloadedRegions_update));
            setDownloadedRegions(downloadedRegions_update)
        } catch (error) {
            console.log(`Failed to add hill: ${error}`)
        }
    }


    if (!availableCounties) {
        return (
          <>
            <div className='navbarmargin'></div>
            <span>Loading...</span>
          </>
        )
      }



    return (
        <>
            <div className='navbarmargin'></div>

            <div>
                <ArrowLeft style={{ position: 'absolute', margin: '5px', color: 'blue' }} size={30} onClick={() => navigate(-1)} />

                <div className="p-2 bg-light text-dark">
                    <div className='text-center'>Available Countries</div>
                </div>
            </div>

            <ListGroup>
                {availableCounties?.regions.map((country) => (
                    countryCode2flag[country.region_code] && <ListGroup.Item key={country.region_code} >
                        <div className="row d-flex align-items-center">
                            <div className="col-3">
                                <img alt='' src={require(`../../assets/flags/${countryCode2flag[country.region_code]}.png`)} />
                            </div>
                            <div className="col-5">
                                {country.name}
                            </div>
                            <div className="col-4 text-end">
                                {downloadedRegions.includes(country.region_code) ? <Check2 size={25} /> :
                                    isDownloading && isDownloadingRegion === country.region_code ?
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </Button> :
                                        country.region_code === 'USA' ?
                                            <Link to="/hilldownload_sub" className="btn btn-primary">
                                                <ArrowRight />
                                            </Link> :
                                            <Button variant="primary" onClick={() => addHills(country.region_code)} disabled={isDownloading}><Download /></Button>

                                }


                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>

        </>
    );


}

export default HillDownload;