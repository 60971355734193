import React , {useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppNavbar from './components/AppNavbar';
import Home from './components/other_tabs/Home'; 
import RankingsPre from './components/rankings/RankingsPre';
import Rankings from './components/rankings/Rankings';
import Profile from './components/profile/Profile';
import Map from './components/Map';
import Trophies from './components/other_tabs/Trophies';
import HillDownload from './components/download_hills/HillDownload';
import DownloadedHills from './components/download_hills/DownloadedHills';
import HillInfo from './components/profile/HillInfo';
import Challenges from './components/challenges/Challenges';
import ChallengeStandings from './components/challenges/ChallengeStandings';
import AccountSettings from './components/other_tabs/AccountSettings';
import FinishedChallenges from './components/challenges/FinishedChallenges';
import LoginPage from './components/auth/LoginPage';
import RegisterPage from './components/auth/RegisterPage';
import ResetPage from './components/auth/ResetPage';
import HillDownloadSub from './components/download_hills/HillDownloadSub';
import InfoAndRules from './components/other_tabs/InfoAndRules';
import Tutorial from './components/other_tabs/Tutorial';
import MainPage from './components/MainPage';
import './styles/App.css';

const App = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedValue = localStorage.getItem('access_token');
    return storedValue !== null ? true : false;
  });

  return (
    <BrowserRouter>
        <AppNavbar isLoggedIn={isLoggedIn}/>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/map" element={<Map />} />
          <Route path="/downloadedhills" element={<DownloadedHills />} />
          <Route path="/hilldownload" element={<HillDownload />} />
          <Route path="/hilldownload_sub" element={<HillDownloadSub />} />
          <Route path="/rankingspre" element={<RankingsPre />} />
          <Route path="/rankings" element={<Rankings />} />
          <Route path="/trophies" element={<Trophies IsMainTab={true}/>} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/hillinfo" element={<HillInfo />} />
          <Route path="/challenges" element={<Challenges />} />
          <Route path="/challengestandings" element={<ChallengeStandings />} />
          <Route path="/challengefinished" element={<FinishedChallenges />} />
          <Route path="/account" element={<AccountSettings setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/reset" element={<ResetPage />} />
          <Route path="/info" element={<InfoAndRules />} />
          <Route path="/tutorial" element={<Tutorial />} />
        </Routes>
    </BrowserRouter>
  );
};

export default App;