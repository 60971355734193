import { useState, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import ImgMenu from '../../assets/tutorial/tut_menubutton.png'
import ImgDownHills from '../../assets/tutorial/tut_openedmenu.png'
import ImgAddHills from '../../assets/tutorial/tut_addhills.png'
import ImgOpenMap from '../../assets/tutorial/tut_openmap.png'
import ImgOpenedMap from '../../assets/tutorial/tut_openedmap.png'
import ImgMapCapture from '../../assets/tutorial/tut_mapcapture.png'
import ImgSync from '../../assets/tutorial/tut_sync.png'
import ImgRank from '../../assets/tutorial/tut_rankings.png'

function Tutorial() {

  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const LastIndex = 7

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };


  const handleButtonClick = (selectedIndex) => {
    if (selectedIndex === LastIndex) {
      navigate('/home')
    } else {
      setIndex(selectedIndex + 1);
    }
  };

  return (
    <>
      <div className='navbarmargin'></div>
      <div className='text-center' style={{ height: 'calc(100vh - 56px)', background: 'linear-gradient(to bottom, #9b0493, #38068f)', }}>
        <Container className='text-white' >

          <div className='row'>
            <h2 className='text-center'>Tutorial</h2>
          </div>

          <div className='row justify-content-center'>
            <Carousel activeIndex={index} onSelect={handleSelect} interval={null} wrap={false}  style={{ width: '90%' }} data-bs-theme="dark" controls={false} indicators={false}>
              <Carousel.Item>
                <p>Open Hill Hunter GPS menu.</p>
                <img alt='' src={ImgMenu} style={{ height: 'auto', maxHeight: '70vh' }}></img>
              </Carousel.Item>
              <Carousel.Item>
                <p>Choose Download Hills tab.</p>
                <img alt='' src={ImgDownHills} style={{ height: 'auto', maxHeight: '70vh' }}></img>
              </Carousel.Item>
              <Carousel.Item>
                <p>Click the plus button and download capturable hills for your region.</p>
                {/* <img alt='' src="https://hips.hearstapps.com/hmg-prod/images/dog-puppy-on-garden-royalty-free-image-1586966191.jpg?crop=0.752xw:1.00xh;0.175xw,0&resize=1200:*" className="d-block w-100" alt="..."></img> */}
                {/* <img alt='' src={ImgAddHills} style={{ height: 'auto', maxHeight: '60vh'  }}></img> */}
                <img alt='' src={ImgAddHills} className="img-fluid" style={{ maxHeight: 'calc(100vh - 220px)' }}></img>
              </Carousel.Item>
              <Carousel.Item>
                <p>Open the online map by clicking the circle button at the bottom right corner.</p>
                <img alt='' src={ImgOpenMap} className="img-fluid" style={{ maxHeight: 'calc(100vh - 220px)' }}></img>
              </Carousel.Item>
              <Carousel.Item>
                <p>Zoom in the map and select a red or purple circle. The circles represent the capturable hills and places.</p>
                <img alt='' src={ImgOpenedMap} className="img-fluid" style={{ maxHeight: 'calc(100vh - 250px)' }}></img>
              </Carousel.Item>
              <Carousel.Item>
                <p>When you select a capturable hill, you can capture the hill by clicking the below button. If you are really there, the capturing will be successful.</p>
                <img alt='' src={ImgMapCapture} className="img-fluid" style={{ maxHeight: 'calc(100vh - 270px)' }}></img>
              </Carousel.Item>
              <Carousel.Item>
                <p>If you captured a hill, synchronize it with server. You will need an internet connection for this step.</p>
                <img alt='' src={ImgSync} className="img-fluid" style={{ maxHeight: 'calc(100vh - 250px)' }}></img>
              </Carousel.Item>
              <Carousel.Item>
                <p>The score and all rankings are recalculated once a day around 1:30 UTC. Your points will be included after next ranking recalculation if you synchronized them with the server.</p>
                <img alt='' src={ImgRank} className="img-fluid" style={{ maxHeight: 'calc(100vh - 270px)' }}></img>
              </Carousel.Item>
            </Carousel>
          </div>

          <div className="fixed-bottom">
            <Button className='w-100 rounded-0' onClick={() => handleButtonClick(index)} style={
              {
                backgroundColor: "#803bf7",
                borderColor: "#803bf7"
              }}>
              {index === LastIndex ? 'Get Started' : 'Next'}
            </Button>
          </div>

        </Container>
      </div>

    </>
  );
}

export default Tutorial;