import { useRef, useState, useEffect } from "react";
import { countryCode2name, countryCode2flag } from "../constants";
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { ArrowRight, CameraFill, Envelope, ArrowDown, ArrowUp } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';

function HillsList(props) {
    // const { state } = useLocation();
    // console.log('hilllist state', state)
    const dataCapturedHills = props.dataCapturedHills
    // console.log('dataCapturedHills', dataCapturedHills)

    const [sortedBy, setSortedBy] = useState('cap_date');
    const [sortedAscending, setSortedAscending] = useState(false);
    const [capturedHillsSorted, setCapturedHillsSorted] = useState(null);
    const existsUnprocessedHill = useRef(null);


    useEffect(() => {
        const capHillsSorted = sortData(dataCapturedHills, sortedBy, sortedAscending)
        setCapturedHillsSorted(capHillsSorted)
    }, [dataCapturedHills, sortedBy, sortedAscending]);

    // get info if there are unprocessed hills
        useEffect(() => {
        existsUnprocessedHill.current = hasUnprocessedItem(dataCapturedHills)
        }, [dataCapturedHills]);


    function hasUnprocessedItem(arr) {
        return arr.some(item => item.processed === 'n');
    }

    function sortData(data, field, sort_ascending = true) {
        const sortedData = [...data].sort((a, b) => {
            if (sort_ascending) {
                return a[field] > b[field] ? 1 : -1;
            } else {
                return a[field] < b[field] ? 1 : -1;
            }
        });
        return sortedData;
    }

    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        setSortedBy(selectedValue)
    };



    return (
        <>
            <div className="row align-items-center my-2 mx-0">
                <div className="col-3">Sort By:</div>
                <div className="col-7">
                    <Form.Control as="select" onChange={(e) => handleDropdownChange(e)} value={sortedBy}>
                        <option value='cap_date'>Date</option>
                        <option value='hight'>Height</option>
                        <option value='name'>Name</option>
                        <option value='region'>Country</option>
                    </Form.Control>

                </div>
                <div className="col-2">
                    <Button variant="outline-primary" onClick={() => setSortedAscending(!sortedAscending)} > {sortedAscending ? <ArrowUp /> : <ArrowDown />}</Button>
                </div>
            </div>


            <ListGroup>
                {capturedHillsSorted?.map((hill, index) => (
                    <ListGroup.Item key={hill.hill_id} onClick={() => props.handleHillJumpToMap(hill)}>
                        <div className="row d-flex align-items-center">
                            <div className="col-3  text-center">
                                <img alt='' src={require(`../../assets/flags/${countryCode2flag[hill.region.substring(0, 3)]}.png`)} />
                                {countryCode2name[hill.region.substring(0, 3)]}
                            </div>
                            <div className="col-7" >
                                <div className="row">
                                    {hill.processed === 'y' ?
                                        <div className="col fw-bold">{hill.name || 'Unknown'}</div>
                                        : <div className="col fw-bold text-danger">{hill.name || 'Unknown'} *</div>}

                                </div>
                                <div className="row">
                                    <div className="col">{hill.hight} m</div>
                                </div>
                                <div className="row">
                                    <div className="col">{hill.cap_date.slice(0, 16).replace(/T/g, ' ')} UTC</div>
                                </div>
                            </div>
                            <div className="col-1">
                                <div className="row">
                                    <div className="col">{hill.is_img === 'Y' && <CameraFill />}</div>
                                </div>
                                <div className="row">
                                    <div className="col">{hill.is_comment === 'Y' && <Envelope />}</div>
                                </div>
                            </div>
                            <div className="col-1 text-end">
                                {/* <Button variant="primary" onClick={() => props.handleHillJumpToMap(hill)}><ArrowRight /></Button> */}
                                <ArrowRight />
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>

            {existsUnprocessedHill.current && <div className="sticky-bottom text-danger">
                <div className='bg-white mx-1'>
                    * - These hills have just been synced with the server. They will be included in all stats after the next recalculation cycle.
                </div>
            </div>}





        </>
    );


}

export default HillsList;