import Container from 'react-bootstrap/Container';

function InfoAndRules() {
    return (
        <>
            <Container className='navbarmargin'>
                <h4>Goal</h4>
                <p>The goal of the Hill Hunter game is to visit and capture real-life locations around the world. The majority of the target locations are hills and mountains. Users receive points for capturing these target locations (hills). The application monitors your lifetime hiking progress, allowing you to compare your standings with other users in your country and around the world. Additionally, you can participate in various challenges and win trophies based on your score, awarded yearly and quarterly.</p>
                <h4>Challenges</h4>
                <p>There are three challenge types: Quantity, Height, and Bounty Challenge. These challenges are evaluated quarterly and yearly on both country and world levels. For more information, check the specific challenge descriptions in the menu under the Challenges tab. It's important to note that if you synchronize your captured hill after 24 hours from the period closure, it won't be taken into account for that particular period. Therefore, it's recommended to synchronize the captured hill with the server as soon as possible.</p>
                <h4>Hill capturing</h4>
                <p>You have to follow these steps to capture a hill successfully: </p>
                <ol>
                    <li>Download capturable hills for chosen region in "Download Hills" tab in the menu.</li>
                    <li>Open the map with all target locations by clicking the bottom right circular button with small map marker icon.</li>
                    <li>Check all possible target locations around you. They are visualized as red or purple circles.</li>
                    <li>Visit a real location represented by the red or purple circle on the map.</li>
                    <li>Select (click) the red or purple circle when you are physically there and your GPS is turned on.</li>
                    <li>Once a circle (hill) is selected, it will change color to yellow, and a new circular button will appear at the top right. Click the top-right "flag" button to capture the hill.</li>
                    <li>If everything is OK you will see a success message, and the captured hill will change the color to blue.</li>
                    <li>Your captured hill is stored locally on your device, but you need to synchronize it with the server to confirm the capture. Click the top-left sync button in the Home tab to synchronize your data with the server. An internet connection is required for this final step. You don't have to do it immediately after capturing the hill, but remember to do it as soon as you have an available internet connection.</li>
                    <li>Check your standings the next day, as rankings and scores are refreshed daily around 01:30 UTC.</li>
                </ol>
                <h4>Download Hills</h4>
                <p>"Download Hills" tab in the menu is used to download capturable hills for chosen region, so you will be able to capture them with poor internet connection or even offline. However, you will need internet connection to load online map properly.</p>
                <h4>Account Settings</h4>
                <p>You can select your country in the Account Settings tab in the menu. The chosen country flag will be displayed next to your username in all rankings, so you can represent your country.</p>
                <h4>Map</h4>
                <p>Open the map with all target locations by clicking the bottom right circular button with small marker icon. All target locations are visualized by a circle with radius 125 meters. You have to be within this distance to be able to capture a hill. Your position accuracy must be 25m and better.</p>
                <p>Target locations (circles) on the map can have these colors:</p>
                <ul>
                    <li>red - capturable hill, not selected</li>
                    <li>purple - capturable bounty hill, not selected</li>
                    <li>blue - already captured hill, not selected</li>
                    <li>yellow - selected hill</li>
                </ul>
                <h4>Rules</h4>
                <p>It is prohibited to share one Hill Hunter account with other persons. It is allowed to have only one active Hill Hunter account per person. It is prohibited to capture target hills without being at the place. Any form of cheating will result in a permanent ban. The organizer of the Hill Hunter game has the right to change hill parameters if necessary.</p>
            </Container>

        </>
    );
}

export default InfoAndRules;